export const NAVIGATION_DATA = [
  {
    path: '#/',
    label: 'Accounting Program for Juristic',
    items: [
      { path: '/for-juristic-accounting?type=condominium', label: 'Accounting Program for Condominium' },
      { path: '/for-juristic-accounting?type=village', label: 'Accounting Program for Village' },
      { path: '/for-juristic-accounting?type=online', label: 'Online Accounting Program' },
    ]
  },
  {
    path: '#/',
    label: 'Management Program for Juristic',
    items: [
      { path: '/for-juristic-management?type=condominium', label: 'Condominium Management Program' },
      { path: '/for-juristic-management?type=village', label: 'Village Management Program' },
      { path: '/for-juristic-management?type=property', label: 'Property Management Program' },
      { path: '/maintenance-management', label: 'Maintenance Management' },
    ]
  },
  {
    path: '#/',
    label: 'Residential Applications[1]',
    items: [
      { path: '/for-juristic-accounting?type=online', label: 'Juristic Applications' },
      { path: '/for-board', label: 'Rental Management Applications' },
      { path: '/for-resident', label: 'Residential Applications[2]' },
    ]
  },
  {
    path: '#/',
    label: 'On-top services',
    items: [
      { path: '/foQus', label: 'E-meeting (e-AGM)' },
      { path: '/visitor-control', label: 'Visitor Control Management and Parking lot control' },
      { path: '/silverman-guard', label: 'Security Systems and License Plate Recognition (LPR)' },
      { path: '/ai-facial', label: 'AI Facial Recognition & Access Control Management System' },
      { path: '/debt-collection', label: 'Automatic Debt Collection System and Legal Services' },
      { path: '/debtcollection', label: 'Automatic Debt Law Collection System and Legal Services' },
      { path: '/settingup-juristic', label: 'Incorporation services and project assignments' },
      { path: '/accounting-service', label: 'Accounting Service' },
      { path: '/audit-service', label: 'Audit Service' },
    ]
  },
  {
    path: '#/',
    label: 'More',
    items: [
      { path: '/switching-to-silverman', label: 'Switching to Silverman' },
      {
        path: '#/',
        label: 'Price and Package',
        items: [
          { path: '/price-and-package', label: 'Package of Accounting and Financial program for juristic' },
          { path: '/price-and-package/foqus-package', label: 'Package of Online Meeting Service Fee (E-AGM & E-Vote)' },
          { path: '/price-and-package/visitor-control', label: 'Package of Visitor Control Management and Parking lot Service Fee' },
          { path: '/price-and-package/debt-law-service', label: 'Package Debt Law Collection System and Legal Services' },
          { path: '/price-and-package/account-service', label: 'Package fee for supplementary accounting services and audit services' },
          { path: '/price-and-package/white-label', label: 'Package of White Label' },
        ],
        style: { borderBottom: '1px solid #D9D9D9' }
      },
      { path: '/seminar', label: 'Seminar' },
      { path: '/informations', label: 'Juristic Knowledge Sources' },
      { path: `/informations/1`, label: 'Juristic Management Manual[1]' },
      { path: `/informations/2`, label: 'How to select Juristic Management Company', style: { borderBottom: '1px solid #D9D9D9' } },
      { path: `/informations/3`, label: 'Blogs' },
      { path: `/informations/4`, label: 'Common Fee Calculation' },
      { path: '/juristic-management-company', label: 'Juristic Management Company’s name lists' },
    ]
  }
]