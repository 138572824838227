import React from 'react';
import { Translation } from "react-i18next";
import { Link } from 'react-router-dom';
import Image from '../../components/image';
import { asset } from '../../helpers/dom.helper';
import i18n, { toRoute } from "../../i18n";
import styles from './footer.module.scss';
import FooterCollapse from './FooterCollapse';
import { FOOTER_LINKS } from './_data';

export class Footer extends React.PureComponent {

  constructor(props) {
    super(props)

    this.state = {
      expandedContents: {
        download: false,
        service: false,
        contact: false
      },
      defaultLinksCount: 3,
      expandedLinks: FOOTER_LINKS.map(() => false)
    }

    this.onExpandedContentToogle = this.onExpandedContentToogle.bind(this)
    this.onExpandedLinkToogle = this.onExpandedLinkToogle.bind(this)
  }

  onExpandedContentToogle(event, entry) {
    event.preventDefault()

    let { expandedContents } = this.state
    expandedContents[entry] = !expandedContents[entry]
    this.setState({ expandedContents: { ...expandedContents } })
  }
  // onExpandedContentToogle(event, entry) {
  //   event.preventDefault();
  
  //   this.setState(prevState => ({
  //     expandedContents: {
  //       ...prevState.expandedContents,
  //       [entry]: !prevState.expandedContents[entry]
  //     }
  //   }));
  // }

  onExpandedLinkToogle(event, index) {
    event.preventDefault()

    let { expandedLinks } = this.state
    expandedLinks[index] = !expandedLinks[index]
    this.setState({ expandedLinks: [...expandedLinks] })
  }


  
  render() {
    const { expandedContents, defaultLinksCount, expandedLinks } = this.state

    return (
      <Translation ns={['translation', 'sitemap']}>
        {t => (
          <div className={styles.footer}>
            <div className="container">
              <div className="row">
                <div className="col-12 col-md-9 mb-4 footer-download">
                  <FooterCollapse title={t('Download Application')} expanded={expandedContents.download} onToggle={e => this.onExpandedContentToogle(e, 'download')} titleClassName="headline d-md-inline-block" collapseClassName="d-md-inline-block ml-md-5 collapse">
                    <div className="row no-gutters">

                      <div className="col pr-md-2">
                        <a href="https://apps.apple.com/th/app/silverman/id1436707310">
                          <Image src={asset('/icon/app-store.webp')} alt="app-store" width="130" height="40" className="footer-download-icon" />
                        </a>
                      </div>

                      <div className="col">
                        <a href="https://play.google.com/store/apps/details?id=com.port80.silverman&hl=en&gl=US">
                          <Image src={asset('/icon/play-store.webp')} alt="play-store" width="130" height="40" className="footer-download-icon" />
                        </a>
                      </div>

                    </div>
                  </FooterCollapse>

                  <hr className="mt-4 mb-0 d-sm-none" style={{ margin: '0 -1rem' }} />
                </div>

                <div className="col-12 col-md mb-4 footer-social">
                  <h5 className="headline d-inline-block d-md-block mr-3">{t('Contact us for more information')}</h5>
                  <a href="https://www.facebook.com/SilvermanApp/" target="_blank"
                    rel="noopener noreferrer">
                    <Image src={asset('/icon/facebook.webp')} alt="facebook" width="40" height="40" className="footer-social-icon" />
                  </a>
                  <a href="https://line.me/ti/p/%40silvermanapp">
                    <Image src={asset('/icon/line.webp')} alt="line" width="40" height="40" className="footer-social-icon" />
                  </a>
                  <a href="https://www.youtube.com/channel/UC8FZEnXfjUEYkrOeY_63N3w" target="_blank"
                    rel="noopener noreferrer">
                    <Image src={asset('/icon/youtube.webp')} alt="youtube" width="40" height="40" className="footer-social-icon" />
                  </a>
                </div>
              </div>
            </div>
            {/* end footer contents: download & social-networks */}

            <hr className="mt-0 mb-3" />

            <div className="container">
              <div className="row">
                <div className="col-12 col-md d-none d-md-block mb-4 footer-about">
                  <h5 className="headline">{t('Silverman App')}</h5>
                  <div className="row">
                    <div className="col-4">
                      <Link to={toRoute("/about")}>{t('About Us')}</Link>
                      <Link to={toRoute("/juristic-support-team")}>{t('Juristic Support Team')}</Link>
                      <Link to={toRoute("/career")}>{t('Careers')}</Link>
                      <Link to={toRoute("/partner-with-us")}>{t('Why Partner')}</Link>
                    </div>

                    <div className="col-4">
                      <Link to={toRoute("/contact")}>{t('Contact Us')}</Link>
                      <Link to={toRoute("/switching-to-silverman")}>{t('Switching to Silverman')}</Link>
                      <Link to={toRoute("/price-and-package")}>{t('Package and Prices')}</Link>
                      <Link to={toRoute("/faq")}>{t('FAQ')}</Link>
                    </div>

                    <div className="col-4">
                      <Link to={toRoute("/videos")}>{t('How to use websites')}</Link>
                      <Link to={toRoute("/account-delete")}>{t('Account Delete')}</Link>
                      <Link to={toRoute("/service-standard")}>{t('Service Standards')}</Link>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-4 mb-4 footer-service">
                  <FooterCollapse title={t('On-top services')} expanded={expandedContents.service} onToggle={e => this.onExpandedContentToogle(e, 'service')}>
                    <div className="row no-gutters">
                      <div className="col-10 order-0 py-1">
                        <ul style={{listStyle:'none',padding:'0'}}> 
                          <li><Link to={toRoute("/foQus")}> {t('E-meeting (e-AGM)')} </Link></li>
                          <li><Link to={toRoute("/visitor-control")}> {t('Visitor Control Management and Parking lot control')} </Link></li>
                          <li><Link to={toRoute("/silverman-guard")}> {t('Security Systems and License Plate Recognition (LPR)')} </Link></li>
                          <li><Link to={toRoute("/ai-facial")}> {t('AI Facial Recognition & Access Control Management System')} </Link></li>
                          <li><Link to={toRoute("/debt-collection")}> {t('Automatic Debt Collection System and Legal Services')} </Link></li>
                          <li><Link to={toRoute("/settingup-juristic")}> {t('Incorporation services and project assignments')} </Link></li>
                          <li><Link to={toRoute("/accounting-service")}> {t('Accounting Service')} </Link></li>
                          <li><Link to={toRoute("/audit-service")}> {t('Audit Service')} </Link></li>
                        </ul>
                      </div>
                      {/* <div className="col-6 order-3 py-1 text-md-right">
                        <a href="https://line.me/ti/p/%40silvermanfoqus" target="_blank" rel="noopener noreferrer">
                          <Image src={asset('/icon/line-foqus.svg')} alt={t('E-meeting (e-AGM) and E-vote')} width="130" height="20" />
                        </a>
                      </div> */}
                    </div>
                  </FooterCollapse>
                </div>
                <div className="col-12 col-md-3 mb-4 footer-service">
                  <FooterCollapse title={t('Connect with us')} expanded={expandedContents.service} onToggle={e => this.onExpandedContentToogle(e, 'service')}>
                    <div className="row no-gutters">
                      <div className="col-10 order-0 py-1">
                        <ul style={{listStyle:'none',padding:'0'}}> 
                          <li>{t('Management & Accounting program')}</li>
                          <li>{t('E-meeting (e-AGM) and E-vote')}</li>
                          <li> <a href="https://line.me/ti/p/%40silvermanapp" target="_blank" rel="noopener noreferrer">
                          <Image src={asset('/icon/line-app.svg')} alt={t('Management & Accounting program')} width="130" height="20" />
                        </a></li>
                        </ul>

                      </div>
                      {/* <div className="col-6 order-2 order-md-1 py-1 text-md-right">
                       
                      </div> */}
                      {/* <div className="col-6 order-1 order-md-2 py-1"></div> */}
                      {/* <div className="col-6 order-3 py-1 text-md-right">
                        <a href="https://line.me/ti/p/%40silvermanfoqus" target="_blank" rel="noopener noreferrer">
                          <Image src={asset('/icon/line-foqus.svg')} alt={t('E-meeting (e-AGM) and E-vote')} width="130" height="20" />
                        </a>
                      </div> */}
                    </div>
                  </FooterCollapse>
                </div>
              </div>
            </div>
            {/* end footer contents: about & service */}

            <hr className="mt-0 mb-3" />

            <div className="container">
              <div className="row">
                <div className="col-12 col-md-4 order-md-1 mb-4 footer-contact">
                  <FooterCollapse title={t('Contact Us')} expanded={expandedContents.contact} onToggle={e => this.onExpandedContentToogle(e, 'contact')}>
                    <ul>
                      <li>
                        <Image src={asset('/icon/clock.svg')} alt={t('Contact Us')} width="24" height="24" className="footer-contact-icon" />
                        <span>{t('Monday-Friday')} : 09:30 - 18:30</span>
                      </li>
                      <li>
                        <Image src={asset('/icon/mobile.svg')} alt={t('Contact Us')} width="24" height="24" className="footer-contact-icon" />
                        <span><a href="tel:0814426888">08-1442-6888</a>, <a href="tel:0655796844">06-5579-6844</a></span>
                      </li>
                      <li>
                        <Image src={asset('/icon/mail.svg')} alt={t('Contact Us')} width="24" height="24" className="footer-contact-icon" />
                        <a href="mailto:hello@silverman.app">hello@silverman.app</a>
                      </li>
                    </ul>
                  </FooterCollapse>

                  <hr className="mt-4 mb-0 d-sm-none" style={{ margin: '0 -1rem' }} />
                </div>

                <div className="col-12 col-md mb-4 footer-partner">
                  <div className="row no-gutters">

                    <div className="col pr-2">
                      <h5 className="headline">Member of</h5>
                      <div className="row flex-column flex-md-row">
                        <div className="col">
                          <a href="http://www.housingbiz.org/" target="_blank" rel="noopener noreferrer">
                            <Image src={asset('/images/footer/partner/house.webp')}
                              alt="house"
                              width="150" height="56" className="footer-partner-icon mr-2" />
                          </a>
                        </div>
                        <div className="col">
                          <a href="http://thaicondo.or.th/" target="_blank" rel="noopener noreferrer">
                            <Image src={asset('/images/footer/partner/condominium.webp')}
                              alt="condominium"
                              width="150" height="56" className="footer-partner-icon" />
                          </a>
                        </div>
                      </div>
                    </div>

                    <div className="col pr-2">
                      <h5 className="headline">Certified by</h5>
                      <div className="row flex-column flex-md-row">
                        <div className="col">
                          <a href="https://www.nstda.or.th/" target="_blank" rel="noopener noreferrer">
                            <Image src={asset('/images/footer/partner/nstda.webp')}
                              alt="nstda"
                              width="150" height="56" className="footer-partner-icon mr-2" />
                          </a>
                        </div>
                        <div className="col">
                          <a href="https://www.depa.or.th/th/home" target="_blank" rel="noopener noreferrer">
                            <Image src={asset('/images/footer/partner/depa.webp')}
                              alt="depa"
                              width="150" height="56" className="footer-partner-icon" />
                          </a>
                        </div>
                      </div>
                      <div className="row flex-column flex-md-row">
                        <div className="col">
                          <a href="https://silverman-storage.sgp1.cdn.digitaloceanspaces.com/website-static/company/web/%E0%B8%AB%E0%B8%99%E0%B8%B1%E0%B8%87%E0%B8%AA%E0%B8%B7%E0%B8%AD%E0%B9%83%E0%B8%9A%E0%B8%A3%E0%B8%B1%E0%B8%9A%E0%B9%81%E0%B8%88%E0%B9%89%E0%B8%87%20digital%20platform%20ETDA.pdf" target="_blank" rel="noopener noreferrer">
                            <Image src={asset('/images/footer/partner/etda-logo.png')}
                              alt="nstda"
                              width="150" height="56" className="footer-partner-icon" />
                          </a>
                        </div>
                        <div className="col" />
                        
                      </div>
                    </div>

                    <div className="col">
                      <h5 className="headline">Awarded by</h5>
                      <div className="row flex-column flex-md-row">
                        <div className="col">
                          <a href="https://www.facebook.com/ThailandICTawards/" target="_blank" rel="noopener noreferrer">
                            <Image src={asset('/images/footer/partner/ticta.webp')}
                              alt="ticta"
                              width="150" height="56" className="footer-partner-icon mr-2" />
                          </a>
                        </div>
                        <div className="col" />
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
            {/* end  footer contents: contact & partner */}
            {/* end footer contents */}

            <div className="container">
              {/* <p className="my-5" dangerouslySetInnerHTML={{ __html: t('<mark>Silverman app</mark> – The Best <mark>Property Management Program</mark> website in Thailand, where is starting of the person who are looking for <mark>Juristic Program</mark> not only <mark>Accounting Program, Condominium Juristic, Accounting Program for Village Juristic, Parcel Tracking program, Repair Notification System, Complain Program, Reserving Common Area Systems, Resident Applications</mark>, including <mark>E-Meeting</mark>. On Silverman App Website will be shown the best <mark>Juristic Management Program</mark> for Apartment, Condominium, Village with various features that will help to easily manage the <mark>juristic management</mark> by AI Technology.') }} ></p> */}
              <p className="my-5 my-desc-footer" >
              <a href='/'>Silverman.app</a> <span dangerouslySetInnerHTML={{__html:t('The Best <mark>Property Management Program</mark>') }}></span> {t('where is starting of the person who are looking for')} <a href='/for-juristic-management'>{t('Juristic Program')}</a> {t('not only')} <a href='/for-juristic-accounting'>{t('Accounting Program')}</a> <a href='/juristic-management-company'> {t('Condominium Juristic')}</a>  <a href='/for-juristic-accounting'>{t('Accounting Program')}</a>
              <a href='/juristic-management-company'> {t('Accounting Program for Village Juristic')}</a>  <a href='/for-resident'>{t('Parcel Tracking program')}</a> <a href='/for-board'>{t('Repair Notification System')}</a> <a href='/for-resident'>{t('Complain Program')}</a>
               <a href='/for-resident'> {t('Reserving Common Area Systems')}</a> <a href='/for-resident'> {t('Resident Applications')}</a> {t('including')} <a href='/foQus'>E-meeting</a> {t('On Silverman App Website will be shown the best')}<a href='/for-juristic-management'>{t('Juristic Management Program')} </a> 
               {t('for Apartment, Condominium, Village with various features that will help to easily manage the')}<a href='/for-juristic-management'>{t('juristic management')}</a> <span  dangerouslySetInnerHTML={{ __html: t('by AI Technology') }} />
              </p>
              {/* end footer abbr */}

              <div className="row footer-links">
                {FOOTER_LINKS.map((menu, menuIndex) => {
                  return (
                    <div className="col-12 col-md-3 mb-4 footer-links-item" key={menuIndex}>
                      <FooterCollapse title={t(menu.label)} expanded={expandedLinks[menuIndex]} onToggle={e => this.onExpandedLinkToogle(e, menuIndex)}>
                        {(expandedLinks[menuIndex] ? menu.items : menu.items.slice(0, defaultLinksCount)).map((it, i) => (
                          <Link to={toRoute(it.url)} className="d-block" key={i}>{t(it.label)}</Link>
                        ))}
                      </FooterCollapse>

                      <a href="#/" className="d-none d-md-block text-danger mt-2" onClick={e => this.onExpandedLinkToogle(e, menuIndex)}>{t(expandedLinks[menuIndex] ? 'Read Less[2]' : 'Read More[2]')}</a>
                    </div>
                  )
                })}
              </div>
              {/* end footer links */}
            </div>

            <div className="footer-nav">
              <div className="container py-3 py-md-0">
                <div className="row align-items-md-center text-center">

                  <div className="col py-2">
                    <Link to={toRoute('/term-of-user')}>{t('Term and Conditions')}</Link>
                  </div>
                  <div className="col py-2">
                    <Link to={toRoute('/privacy')}>{t('Privacy Policy')}</Link>
                  </div>
                  <div className="col-12 col-md py-2">
                    <Link to={toRoute('/sitemap')}>{t('Silverman Sitemap')}</Link>
                  </div>
                  <div className="col-12 col-md py-2">
                    <Link to={'/!#'}>{t('Copyright')}</Link>
                  </div>

                </div>
              </div>
            </div>
            {/* end footer nav */}
          </div>
        )}
      </Translation>
    )
  }

}
